// src/config/tourSteps.ts
import { Step } from 'react-joyride';

export const tourSteps: Step[] = [
 
  {
    target: ".dash-step-1",
    content: "These are called Hubs. Each Hub is an individually trained expert in its field.",
  },
  {
    target: ".dash-step-2",
    content: "The info icon gives you an overview of what the trained expert can help you with.",
  },
  {
    target: ".dash-step-3",
    content: "The search bar allows you to search for the Chat Name across all hubs. Showing the date it was created and what Hub it belongs to. When clicked, it will take directly to the chat.",
  },
  {
    target: ".dash-step-4",
    content: "The Resource Hub is a collection of resources that can help you with your AI journey Video demos of not only, Sloane but other Ai tips and tricks as well.",
  },
  {
    target: ".dash-step-5",
    content: "Click this button to update your business model.",
  },
  // Add more steps as needed
];