import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useAuth } from "@clerk/clerk-react";
import LoadingChatBubble from "../components/loadingChatBubble";
import { service } from "../services";
import { useFolder } from "./FolderContext"; // Ensure correct import path
import { useAssistant } from "./assistantContext"; // Ensure correct import path

interface IChatContextState {
  selectedChat: any;
  setSelectedChat: (chat: any) => void;
  selectChat: (chatId: string) => Promise<void>;
  allChats: any[];
  moveChat: (chatId: string, folderId?: string) => Promise<void>;
  sendChat: (prompt: string, room: string) => Promise<void>;
  createChat: (folderId?: string) => Promise<any>;
  isMessageLoading: boolean;
  getAllChats: () => Promise<void>;
  question: string;
  sendQuestion: (prompt: string) => void;
}

const ChatContext = createContext<IChatContextState | undefined>(undefined);

export const useChat = () => useContext(ChatContext)!;

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getToken } = useAuth();
  const { selectedAssistant } = useAssistant();
  const { setFolderChats } = useFolder();
  const [selectedChat, setSelectedChat] = useState<any>(undefined);
  const [allChats, setAllChats] = useState<any[]>([]);
  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");

  // Memoise getAllChats to avoid recreating it on every render
  const getAllChats = useCallback(async () => {
    const token = await getToken();
    if (!token || !selectedAssistant) return;
    const chats = await service.chatService.getChatsByAssistant(token, selectedAssistant);
    setAllChats(chats || []);
  }, [getToken, selectedAssistant]); // Ensure dependencies are correctly set

  const selectChat = async (chatId: string) => {
    const token = await getToken();
    if (!token) return;
    const response = await service.chatService.getChatById(token, chatId);
    setSelectedChat(response);
  };

  const sendChat = async (prompt: string, room: string) => {
    setIsMessageLoading(true);
    const token = await getToken();
    setQuestion(prompt);

    let chatId = selectedChat?._id;
    if (!selectedChat) {
      const chat = await createChat();
      chatId = chat._id;
    }

    const tempMessageId = Date.now().toString();

    const optimisticMessage = {
      _id: tempMessageId,
      question: prompt,
      component: <LoadingChatBubble />,
      status: "pending",
    };

    setSelectedChat((currentChat: any) => ({
      ...currentChat,
      messages: [...(currentChat?.messages || []), optimisticMessage],
    }));

    try {
      const response = await service.chatService.sendMessage(token, prompt, chatId, room);

      if (response && response.response) {
        setSelectedChat((currentChat: any) => {
          const updatedMessages = currentChat.messages.map((message: any) =>
            message._id === tempMessageId
              ? {
                  ...message,
                  answer: response.response,
                  status: "completed",
                  component: undefined,
                }
              : message
          );
          return { ...currentChat, messages: updatedMessages };
        });
      } else {
        throw new Error("Invalid response received");
      }
    } catch (error) {
      console.error("Error sending chat message:", error);
      setSelectedChat((currentChat: any) => {
        const updatedMessages = currentChat.messages.map((message: any) =>
          message._id === tempMessageId
            ? { ...message, answer: "Failed to get response", status: "failed" }
            : message
        );
        return { ...currentChat, messages: updatedMessages };
      });
    } finally {
      setIsMessageLoading(false);
    }
  };

  const createChat = async (folderId?: string) => {
    const token = await getToken();
    if (!token || !selectedAssistant) {
      console.error("Token or Assistant ID is missing");
      return;
    }

    const body = {
      assistantId: selectedAssistant,
      ...(folderId && { folderId }),
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/chats/create-chat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    if (!folderId) {
      setAllChats((prev) => [...prev, data]); // Correctly using the callback to update state
    } else {
      setFolderChats((prev: any) => [...prev, data]); // Correctly using the callback to update state
    }
    setSelectedChat(data);
    return data;
  };

  const moveChat = async (chatId: string, folderId?: string) => {
    const token = await getToken();
    if (!token) {
      console.error("Token is missing");
      return;
    }

    try {
      const response = await service.chatService.moveChats(token, folderId!, chatId);
      if (response) {
        if (folderId) {
          setFolderChats((prev: any) => [...prev, response.chat]); // Correctly using the callback
          setAllChats((prev: any) =>
            prev.filter((chat: any) => chat._id !== chatId)
          ); // Correctly using the callback
        } else {
          const chatAlreadyInAllChats = allChats.some(
            (chat: any) => chat._id === chatId
          );

          if (chatAlreadyInAllChats) {
            return;
          }

          setAllChats((prev: any) => [...prev, response.chat]);
          setFolderChats((prev: any) =>
            prev.filter((chat: any) => chat._id !== chatId)
          );
        }
      }
    } catch (error) {
      console.error("Error moving chat:", error);
    }
  };

  const sendQuestion = (prompt: string) => {
    setQuestion(prompt);
    sendChat(prompt, "defaultRoom"); // Adjust room logic as needed
  };

  // useEffect to call getAllChats when selectedAssistant changes
  useEffect(() => {
    if (selectedAssistant) {
      getAllChats();
    }
  }, [selectedAssistant, getAllChats]); // Include getAllChats in the dependency array

  return (
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        selectChat,
        allChats,
        moveChat,
        sendChat,
        createChat,
        isMessageLoading,
        getAllChats,
        question,
        sendQuestion,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
