// src/context/PromptContext.tsx

import React, { createContext, useState, useContext, ReactNode } from 'react';

interface Prompt {
  text: string;
  prompt: string;
  assistantId: string;
}

interface PromptContextType {
  prompts: Prompt[];
  addPrompt: (prompt: Prompt) => void;
}

const PromptContext = createContext<PromptContextType | undefined>(undefined);

export const usePrompts = () => {
  const context = useContext(PromptContext);
  if (!context) {
    throw new Error('usePrompts must be used within a PromptProvider');
  }
  return context;
};

export const PromptProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);

  const addPrompt = (prompt: Prompt) => {
    setPrompts((prevPrompts) => [...prevPrompts, prompt]);
    console.log('Prompts after adding:', [...prompts, prompt]);
  };

  return (
    <PromptContext.Provider value={{ prompts, addPrompt }}>
      {children}
    </PromptContext.Provider>
  );
};
