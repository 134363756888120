import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
  } from "react";
  import { useAuth } from "@clerk/clerk-react";
  import { service } from "../services";
  import { useAssistant } from "./assistantContext";
  
  interface IFolderContext {
	selectedFolder: any;
	setSelectedFolder: (folder: any) => void;
	allFolders: any[];
	folderChats: any[];
	setFolderChats: React.Dispatch<React.SetStateAction<any[]>>;
	getAllFolders: () => Promise<void>;
	getAllFolderChats: (folderId: string) => Promise<void>;
	createFolder: () => Promise<any>;
  }
  
  const FolderContext = createContext<IFolderContext | undefined>(undefined);
  
  export const useFolder = () => useContext(FolderContext)!;
  
  export const FolderProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
  }) => {
	const { getToken } = useAuth();
	const { selectedAssistant } = useAssistant();
	const [selectedFolder, setSelectedFolder] = useState<any>(undefined);
	const [allFolders, setAllFolders] = useState<any[]>([]);
	const [folderChats, setFolderChats] = useState<any[]>([]);
  
	const getAllFolders = useCallback(async () => {
	  const token = await getToken();
	  if (!token || !selectedAssistant) return;
	  const folders = await service.chatService.getFoldersByAssistant(
		token,
		selectedAssistant,
	  );
	  setAllFolders(folders);
	}, [getToken, selectedAssistant]);
  
	const getAllFolderChats = useCallback(
	  async (folderId: string) => {
		const token = await getToken();
		if (!token) return;
		const chats = await service.chatService.getChatsByFolder(token, folderId);
		setFolderChats(chats || []); // Ensure folderChats is set to an array
		console.log(chats); // You can remove this console.log if it's no longer needed
	  },
	  [getToken], // Removed folderChats from the dependency array
	);
  
	const createFolder = async () => {
	  const token = await getToken();
	  if (!token || !selectedAssistant) return;
	  const response = await fetch(
		`${process.env.REACT_APP_API_BASE_URL}/api/chats/create-folder`,
		{
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		  },
		  body: JSON.stringify({ assistantId: selectedAssistant }),
		},
	  );
	  const data = await response.json();
	  setAllFolders((prev) => [...prev, data]);
	  return data;
	};
  
	useEffect(() => {
	  if (selectedAssistant) {
		getAllFolders();
	  }
	}, [selectedAssistant, getAllFolders]);
  
	return (
	  <FolderContext.Provider
		value={{
		  selectedFolder,
		  setSelectedFolder,
		  allFolders,
		  folderChats,
		  setFolderChats,
		  getAllFolders,
		  getAllFolderChats,
		  createFolder,
		}}
	  >
		{children}
	  </FolderContext.Provider>
	);
  };
  