import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import Icon from "./Icons/Icon";
// import elena from "../assets/elena.png"; // Ensure this path is correct

// Keyframes for the typing animation
const typingAnimation = keyframes`
  0% { opacity: 0.1; }
  50% { opacity: 1; }
  100% { opacity: 0.1; }
`;

// Styled component for the dots
const Dot = styled("div")(({ theme }) => ({
	height: "10px",
	width: "10px",
	backgroundColor: "grey",
	borderRadius: "50%",
	display: "inline-block",
	animation: `${typingAnimation} 1.4s infinite ease-in-out`,
	"&:nth-of-type(1)": {
		animationDelay: "0s",
	},
	"&:nth-of-type(2)": {
		animationDelay: "0.2s",
	},
	"&:nth-of-type(3)": {
		animationDelay: "0.4s",
	},
}));

// LoadingChatBubble component
const LoadingChatBubble = () => {
	const isMobile = useMediaQuery("(max-width:600px)");

	return (
		<Box
			sx={{
				marginBottom: 2,
				paddingX: isMobile ? 2 : 5,
				display: "flex",
			}}
		>
			<Box sx={{ marginTop: 3 }}>
				<Box display="flex" alignItems="center">
					{/* <Avatar src={elena} alt="Elena" sx={avatarStyle} /> */}
					<Icon name="ai" className="mr-2" />
					<Typography variant="body1">AI</Typography>
				</Box>
				<Typography
					component="div"
					sx={{
						borderRadius: "20px",
						// bgcolor: isResponse ? "primary.light" : "secondary.light", // Change the colors as needed
						color: "000",
						wordWrap: "break-word",
						marginLeft: 1,
						textAlign: "left",
						marginTop: 1,
					}}
				>
					<Dot />
					<Dot />
					<Dot />
				</Typography>
			</Box>
		</Box>
	);
};

export default LoadingChatBubble;
