// import { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import { useTheme } from "./ThemeContext";
// import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"; // Import the recording icon

interface IProps {
  room: string;
}

// Quick TypeScript workaround
// declare global {
//   interface Window {
//     webkitSpeechRecognition: any;
//     SpeechRecognition: any;
//   }
// }

const TextInput: React.FC<IProps> = ({ room }) => {
  const { sendChat } = useChat();
  const { theme } = useTheme();
  // const [isListening, setIsListening] = useState(false); // State to manage microphone status
  // const [recognition, setRecognition] = useState<any>(null);



  // Initialize Formik
  const formik = useFormik({
    initialValues: { question: "" }, // Initial values of the form
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      sendChat(values.question, room);
      formik.setFieldValue("question", "");
      resetForm();
    },
  });

  // Initialize SpeechRecognition
  // const handleSpeechRecognition = () => {
  //   if (
  //     !("webkitSpeechRecognition" in window || "SpeechRecognition" in window)
  //   ) {
  //     alert("Your browser does not support speech recognition.");
  //     return;
  //   }

  //   const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;
  //   const recognitionInstance = new SpeechRecognition();
  //   recognitionInstance.continuous = true;
  //   recognitionInstance.interimResults = true;
  //   recognitionInstance.lang = "en-AU";

  //   recognitionInstance.onstart = () => {
  //     setIsListening(true); // Set isListening to true when recording starts
  //     if (startSoundRef.current) {
  //       startSoundRef.current.play(); // Play start sound
  //     }
  //   };

  //   recognitionInstance.onresult = (event: any) => {
  //     const transcript = event.results[0][0].transcript;
  //     formik.setFieldValue("question", formik.values.question + transcript);
  //   };

  //   recognitionInstance.onerror = (event: any) => {
  //     console.error("Speech recognition error:", event);
  //   };

  //   recognitionInstance.onend = () => {
  //     setIsListening(false); // Reset isListening when recording stops
  //   };

  //   recognitionInstance.start();
  //   setRecognition(recognitionInstance);
  // };

  // Handle stop recognition
  // const stopSpeechRecognition = () => {
  //   if (recognition) {
  //     recognition.stop();
  //     setIsListening(false);
  //     if (stopSoundRef.current) {
  //       stopSoundRef.current.play(); // Play stop sound
  //     }
  //   }
  // };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`${
          theme === "dark" ? "bg-brand-logo" : "bg-brand-green"
        } rounded-[30px] px-4 mb-4 flex justify-center h-contain items-center text-brand-green py-[1px]`}
      >
        <TextField
          id="question"
          name="question"
          onChange={formik.handleChange}
          value={formik.values.question}
          placeholder="Ask a question..."
          className="bg-brand-blue-light w-full px-2 focus:outline-none text-brand-green overflow-y-auto max-h-[200px] lg:max-h-[400px] 1xl:max-h-[600px] text-wrap rounded-lg textarea1-scrollbar"
          multiline
          onKeyDown={(e) => {
            if (e.shiftKey && e.key === "Enter") {
              e.preventDefault();
              formik.setFieldValue("question", formik.values.question + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
          sx={{
            backgroundColor: "bg-brand-blue-light",
            color: "#00BF63",
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
            "& .MuiInputBase-input": {
              backgroundColor:
                theme === "dark" ? "bg-brand-logo" : "bg-brand-green",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "transparent",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            borderRadius: "rounded-md",
            border: "none",
            outline: "none",
          }}
        />

        {/* Microphone or Recording Icon Button */}
        {/* <button
          type="button"
          onClick={
            isListening ? stopSpeechRecognition : handleSpeechRecognition
          }
          className="flex items-end justify-start"
        >
          <div className="w-8 h-7">
            {isListening ? (
              <RadioButtonCheckedIcon
                style={{ color: "#FF7043", marginRight: '6px' }} // Use your brand orange dark colour here
              />
            ) : (
              <Icon name="microphone" />
            )}
          </div>
        </button> */}

        <button type="submit" className="flex items-end justify-start">
          <div className="w-8 h-7">
            <Icon name="submit" />
          </div>
        </button>

        {/* Audio Elements for Sound Effects */}
        {/* <audio ref={startSoundRef}>
          <source src="/sounds/start-recording.mp3" type="audio/mpeg" />
        </audio>
        <audio ref={stopSoundRef}>
          <source src="/sounds/stop-recording.mp3" type="audio/mpeg" />
        </audio> */}
      </div>
    </form>
  );
};

export default TextInput;
