/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import { service } from "../services"; // Ensure this path is correct

interface IAssistantContext {
	selectedAssistant: string | null;
	setSelectedAssistant: (assistantId: string | null) => void;
	getPrompts: () => void;
	prompts: IPrompt[];
}

interface IPrompt {
	display: string;
	prompt: string;
	_id: string;
}

const AssistantContext = createContext<IAssistantContext | undefined>(
	undefined,
);

export const useAssistant = () => useContext(AssistantContext)!;

export const AssistantProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { getToken } = useAuth();
	const [selectedAssistant, setSelectedAssistant] = useState<string | null>(
		null,
	);
	const [prompts, setPrompts] = useState<IPrompt[]>([]);

	const getPrompts = async () => {
		const token = await getToken();
		if (!token || !selectedAssistant) return;
		const assistant = await service.assistantService.getAssistant(
			token,
			selectedAssistant,
		);
		console.log(assistant);
		setPrompts(assistant.prompts);
	};

	useEffect(() => {
		if (selectedAssistant) {
			getPrompts();
		}
	}, [selectedAssistant]);

	return (
		<AssistantContext.Provider
			value={{ selectedAssistant, setSelectedAssistant, getPrompts, prompts }}
		>
			{children}
		</AssistantContext.Provider>
	);
};
