import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SignUp from "./components/Pages/SignUp/Login";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import MenuBar from "./components/Menu/MenuBar";
import Chat from "./components/Pages/chat/Chat";
import Admin from "./components/Pages/admin";
import AssistantForm from "./components/Pages/AssistantForm";
import UserForm from "./components/Pages/UserForm";
import UpdateAssistant from "./components/Pages/UpdateAssistant";
import AllUsers from "./components/Pages/AllUsers";
import PleaseSubscribe from "./components/Pages/Dashboard/PleaseSubscribe";
import Resources from "./components/Pages/Resources/Resources";
import PromptForm from "./components/Pages/PromptForm";
import UserChats from "./components/Pages/UserChats";
import UpdateUserForm from "./components/Pages/updateUserForm";
import UsersList from "./components/Pages/UsersList";
import ConfigPage from "./components/Pages/config";

const AppContent: React.FC = () => {
	const location = useLocation();
	const hideMenuBarPaths = ["/userform"];

	const shouldHideMenuBar = hideMenuBarPaths.some((path) =>
		location.pathname.startsWith(path),
	);

	return (
		<div>
			{!shouldHideMenuBar && <MenuBar />}
			<Routes>
				<Route path="/" element={<SignUp />} />
				<Route path="resources" element={<Resources />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/assistant/:assistantId" element={<Chat />} />
				<Route path="/chat" element={<Chat />} />
				<Route
					path="/assistantform"
					element={<AssistantForm isUpdate={false} />}
				/>
				<Route path="/updateAssistant" element={<UpdateAssistant />} />
				<Route
					path="/updateAssistant/:_id"
					element={<AssistantForm isUpdate={true} />}
				/>
				<Route path="/admin" element={<Admin />} />
				<Route path="/userform" element={<UserForm />} />
				<Route path="/updateUser/:id" element={<UpdateUserForm />} />
				<Route path="/allUsers" element={<AllUsers />} />
				<Route
					path="/subscribe"
					element={<PleaseSubscribe onClickSubscribe={() => {}} />}
				/>
				<Route path="/create-prompt" element={<PromptForm />} />
				<Route path="/config" element={<ConfigPage />} />
				<Route path="/get-chats" element={<UsersList />} />
				<Route path="/get-chats/:userId" element={<UserChats />} />
			</Routes>
		</div>
	);
};

export default AppContent;
