import React, { useState } from "react";
import { motion } from "framer-motion";
import VidModal from './VidModal';
import ReactPlayer from 'react-player';

interface VideoTileProps {
    title?: string;
    videoUrl: string;
    backgroundImage: string;
}

const VideoTile: React.FC<VideoTileProps> = ({ title, videoUrl, backgroundImage }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleTileClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isModalOpen) {
            console.log("Tile clicked, opening modal");
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        console.log("Closing modal");
        setModalOpen(false);
    };

    return (
        <div className="flex flex-col items-center h-full w-full">
            <motion.div
                className="relative border-brand-cream border-[1px] w-full h-full text-center rounded-lg shadow-lg flex flex-col justify-center items-center hover:shadow-2xl hover:scale-110 hover:transition-all hover:border-2 hover:border-brand-orange active:border-3 active:border-brand-orange active:underline active:cursor-pointer p-2 px-8 md:px-4"
                style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.7 }}
                onClick={handleTileClick}
            >
                {/* The tile content goes here */}
            </motion.div>
            <div className="mt-2 text-lg md:text-2xl font-black text-brand-cream">
                {title}
            </div>

            {isModalOpen && (
                <VidModal isOpen={isModalOpen} onClose={handleCloseModal}>
                    <div className="relative pt-[56.25%]">
                        <ReactPlayer
                            url={videoUrl}
                            className="absolute top-0 left-0"
                            width="100%"
                            height="100%"
                            controls
                        />
                    </div>
                </VidModal>
            )}
        </div>
    );
};

export default VideoTile;
