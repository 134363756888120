import React, { useState } from "react";
import { useTheme } from "./ThemeContext";
import { useChat } from "../../../context/ChatContext";
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import { service } from "../../../services";
import { useAuth } from "@clerk/clerk-react";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDrag } from "react-dnd";
import { useAssistant } from "../../../context/assistantContext";
import { useFolder } from "../../../context/FolderContext";

const ChatPreview: React.FC<any> = ({ chat }) => {
	const { theme } = useTheme();
	const { selectChat, getAllChats, selectedChat, setSelectedChat } = useChat();
	const { selectedFolder, getAllFolderChats } = useFolder();
	const { selectedAssistant } = useAssistant();
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const [editedTitle, setEditedTitle] = useState(chat.title);
	const { getToken } = useAuth();
	const navigate = useNavigate();

	const [{ isDragging }, dragRef] = useDrag({
		type: "CHAT", // The type of item being dragged, should match the target type
		item: { id: chat._id }, // The data to be passed to the drop target
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const handleEditClick = (event: any) => {
		event.stopPropagation(); // Prevent event bubbling
		setIsEditingTitle(true);
	};

	const handleKeyDown = async (event: any) => {
		if (event.key === "Enter") {
			event.preventDefault(); // Call your API update function here
			const lengthOfWords = editedTitle?.split(" ").filter(Boolean).length;
			if (lengthOfWords < 5) {
				await service.chatService.updateChatName(
					await getToken(),
					editedTitle,
					chat._id,
				);
				setIsEditingTitle(false); // Exit editing mode
				chat.title = editedTitle;
			} else {
				toast.error("Please Enter a title four words");
			}
		}
	};

	const handleDeleteChat = async (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.stopPropagation();
		// Display confirmation dialog
		const isConfirmed = window.confirm(
			"Deleting this chat is permanent and it can never be recovered. Click OK to delete or Cancel if you do not want to delete it.",
		);

		// Proceed with deletion if confirmed
		if (isConfirmed) {
			await service.chatService.deleteChat(await getToken(), chat._id);
			getAllChats();
			getAllFolderChats(selectedFolder);
			if (selectedChat._id === chat._id) {
				setSelectedChat(undefined);
				navigate(`/assistant/${selectedAssistant}`);
			}
		}
	};

	const truncatedString = (text: string) => {
		if (text.length <= 15) {
			return <span>{text}</span>;
		} else {
			const truncatedText = text.substring(0, 20) + "...";
			return <span>{truncatedText}</span>;
		}
	};

	return (
		<div
			ref={dragRef} // Reference to make this element draggable
			className={`w-full chat-preview ${isDragging ? "opacity-50" : ""}`} // Add some visual feedback when dragging
		>
			{!isEditingTitle ? (
				<div
					className={`flex items-center justify-between py-2 px-4 mb-4 rounded-[100px] bg-brand-green text-[14px] font-thin text-brand-cream hover:border-brand-dark-green hover:text-brand-green-dark active:bg-brand-logo active:text-brand-green-dark w-full hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105  ${
						chat._id === selectedChat?._id
							? "border-brand-green border-[1px] !text-brand-green-dark bg-brand-logo"
							: ""
					}`}
					onClick={() => {
						navigate(`/assistant/${selectedAssistant}?chat=${chat._id}`);
					}}
				>
					<div className="flex items-center">
						<ChatIcon />
						<span className="flex-1 ml-4">{truncatedString(chat.title)}</span>
					</div>
					<div className="flex">
						<button
							onClick={handleEditClick}
							className="flex items-center justify-center ml-4 step-8"
						>
							<EditIcon />
						</button>
						<button className="close-icon step-9" onClick={handleDeleteChat}>
							<CloseIcon sx={{ color: "white", marginLeft: "0px" }} />
						</button>
					</div>
				</div>
			) : (
				<div
					className={`flex items-center justify-between py-2 px-4 mb-4 rounded-full border-brand-green-dark border-2 w-full ${
						theme === "dark"
							? "border-brand-blue-light text-white"
							: "border-gray-400 text-white"
					} hover:border-brand-green-dark hover:text-brand-green-dark active:bg-brand-orange active:text-brand-green-dark ${
						isEditingTitle ? "h-12" : "h-12"
					}`}
					onClick={!isEditingTitle ? () => selectChat(chat._id) : undefined}
				>
					{!isEditingTitle ? (
						<>
							<ChatIcon />
							<span className="flex-1 ml-4 ">{chat.title}</span>
							<button
								onClick={handleEditClick}
								className="flex items-center justify-center ml-4 "
							>
								<EditIcon />
							</button>
						</>
					) : (
						<input
							type="text"
							value={editedTitle}
							onChange={(e) => setEditedTitle(e.target.value)}
							onKeyDown={handleKeyDown}
							autoFocus
							onBlur={() => {
								setIsEditingTitle(false);
							}}
							className="bg-transparent border-none outline-none w-full h-full px-4 py-2 text-brand-green-dark "
							style={{ height: "90%", fontSize: "14px" }} // Ensures input field matches the container height and font size
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ChatPreview;
