import { IAssistant } from "../types/interfaces";

export const assistantService = {
	/**
	 * Retrieves all assistants from the API.
	 *
	 * @returns {Promise<IAssistant[]>} A promise that resolves to an array of IAssistant objects.
	 */
	getAssistants: async (token: string | null): Promise<IAssistant[]> => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/get-all-assistants`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.json();
	},
	getAssistant: async (token: string | null, _id: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/assistants/get-assistant/${_id}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		if (!response.ok) {
			throw new Error("Failed to fetch assistant");
		}
		return response.json();
	},
};
