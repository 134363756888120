import React from "react";
import { FaFileAlt } from "react-icons/fa";

interface ModalButtonProps {
  onClick: () => void;
}

const ModalButton: React.FC<ModalButtonProps> = ({ onClick }) => {
  return (
    <div className="relative group">
      <button
        onClick={onClick}
        className="w-16 h-16 rounded-full bg-brand-logo flex justify-center items-center shadow-lg border-[1px] border-brand-green-dark group-hover:bg-brand-green-dark transition-colors duration-300"
      >
        <FaFileAlt className="text-brand-green text-3xl group-hover:text-brand-white transition-colors duration-300" />
      </button>
      <div className="absolute bottom-full right-0 mb-1 px-2 py-1 bg-brand-green-dark text-brand-white text-md rounded-md whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
        Update your business model
      </div>
    </div>
  );
};

export default ModalButton;
