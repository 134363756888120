// src/config/tourStepsChat.ts
import { Step } from "react-joyride";

export const tourStepsChat: Step[] = [
  {
    target: ".step-1",
    content: "Click to begin a new chat with Sloane's expert, or choose a Quick Prompt below.",
  },
  {
    target: ".step-2",
    content: "Select a Quick Prompt to begin chatting with Sloane's expert.",
  },
  {
    target: ".step-3",
    content: "Click to hide or show the Quick Prompts",
  },
  {
    target: ".step-4",
    content: "Toggle between light mode and dark mode for your colour preference.",
  },
  {
    target: ".step-5",
    content: "Quick Links is Sloane's built-in workflow. Click to jump to a suggested Hub after you've finished in this current Hub.",
  },
  {
    target: ".step-6",
    content: "Back to access all Hubs on the Dashboard.",
  },
  {
    target: ".step-7",
    content: "Create a New Chat once you've already started chatting with Sloane's expert.",
  },
  {
    target: ".step-8",
    content: "Create a Folder to organize your chats. Drag and drop chats into folders to keep things tidy. Once created, click the folder to view all chats within the folder.",
  },
 
];
