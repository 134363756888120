import { useAuth } from '@clerk/clerk-react';
import { useState, useEffect } from 'react';

export const useSubscriptionStatus = () => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { getToken } = useAuth();

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/check-subscription`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Add your auth headers if needed
                        "Authorization": `Bearer ${await getToken()}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data)
                setIsSubscribed(data.isActiveSubscription);
            } catch (error) {
                console.error('Failed to fetch subscription status:', error);
                // Handle error - for example, you might want to set isSubscribed to false
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [getToken]);

    return { isSubscribed, isLoading };
};
