// src/components/UserChats.tsx
import { useAuth } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Markdown from "./chat/Markdown";

interface Message {
  question: string;
  answer: string;
}

interface Chat {
  _id: string;
  title: string;
  messages: Message[];
  assistant: {
    _id: string;
    name: string;
  } | null;
  createdAt: string;
}

const UserChats: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [chats, setChats] = useState<Chat[]>([]);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const token = await getToken();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/chats/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        // Sort chats by createdAt in descending order
        const sortedChats = data.sort(
          (a: Chat, b: Chat) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setChats(sortedChats);
      } catch (error) {
        console.error("Failed to fetch chats", error);
      }
    };

    fetchChats();
  }, [userId, getToken]);

  const handleBackClick = () => {
    window.history.back(); // Takes the user back to the previous page
  };

  return (
    <div className="p-8 bg-brand-green min-h-screen">
      <h2 className="text-6xl font-bold mt-12 text-brand-cream text-center">
        Chats for User
      </h2>
      <p
        className="text-brand-green-dark font-Black hover:text-brand-cream hover:underline hover:cursor-pointer text-center mb-8"
        onClick={handleBackClick}
      >
        Back
      </p>
      {chats.length > 0 ? (
        chats.map((chat) => (
          <div
            key={chat._id}
            className="mb-4 p-4  rounded-xl bg-gray-200 shadow-lg border-[1px] border-brand-green-dark"
          >
            <h3 className="text-xl font-semibold">{chat.title}</h3>
            <p className="text-black">
              Assistant: {chat.assistant?.name ?? "No Name"} -{" "}
              {new Date(chat.createdAt).toLocaleDateString()}
            </p>
            <ul className="mt-2">
              {chat.messages.map((message, index) => {
                return (
                  <li key={index} className="mb-2">
                    <p className=" text-4xl">
                      <strong>Q:</strong>
                    </p>
                    <div className="my-4 text-[22px] font-semibold text-brand-green ml-2">
                      {message.question}
                    </div>
                    <p className=" text-4xl">
                      <strong>A:</strong>
                    </p>
                    <Markdown
                      data={message.answer}
                      markdownClassesProps={
                        "pl-10 text-gray-400 hover:text-gray-700"
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ))
      ) : (
        <p>No chats found for this user.</p>
      )}
    </div>
  );
};

export default UserChats;
