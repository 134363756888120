import { useNavigate, useParams } from "react-router-dom";
import { useChat } from "../../../context/ChatContext"; // Assuming ChatContext path
import { useFolder } from "../../../context/FolderContext"; // Assuming FolderContext path
import { useTheme } from "./ThemeContext"; // Ensure correct import path
import Icon from "../../Icons/Icon";
import ChatPreview from "./ChatPreview";
import FolderPreview from "./FolderPreview";
import { useDrop } from "react-dnd";

interface IProps {
  setIsSidebarOpen: (value: boolean) => void;
}

const Sidebar = ({ setIsSidebarOpen }: IProps) => {
  const navigate = useNavigate();
  const { assistantId } = useParams(); // Get assistantId from the URL
  const { theme } = useTheme(); // Use the theme from context
  const { allChats, createChat, moveChat } = useChat(); // Use chat context
  const { allFolders, createFolder } = useFolder(); // Use folder context

  // const button1Class = `text-[14px] ${
  //   theme === "dark" ? "bg-brand-logo" : "bg-brand-logo"
  // } hover:bg-blue-700 text-brand-green font-bold py-2 px-4 rounded-[100px] mb-4 pr-16 hover:bg-brand-cream active:bg-brand-orange active:text-brand-blue flex justify-center `;

  const button2Class = ` ${
    theme === "dark" ? "bg-brand-cream" : "bg-brand-cream"
  }  font-bold p-3 rounded-full mb-1   flex justify-center items-center border-2 border-brand-green`;

  // Droppable area logic with feedback on hover
  const [{ isOver }, drop] = useDrop({
    accept: "CHAT",
    drop: (item: { id: string }) => moveChat(item.id, undefined),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  // Change background color if hovering over the droppable area
  const dropAreaClass = `w-full mt-2 mb-4 pt-4 pb-16 pr-4 rounded-3xl flex flex-col justify-start items-start ${
    isOver
      ? "bg-brand-cream border-2 border-dashed border-brand-green rounded-3xl opacity-50"
      : ""
  }`;

  return (
    <div className="flex flex-col w-full items-start pt-20 px-4 h-[95%] overflow-y-auto sidebar-container ">
      {/* Sideboard navigation links */}
      <div className="flex w-full justify-between">
        {/* Link 1 */}
        <div className="flex flex-col justify-center items-center mb-4 hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 step-6">
          <button
            className={`${button2Class} `}
            onClick={() => navigate("/dashboard")}
          >
            <div className="w-10 h-10">
              <Icon name="dashboard" className=" text-brand-green w-10" />
            </div>
          </button>
          <p className="text-xs">Dashboard</p>
        </div>

        {/* Link 2 */}

        <div className="flex flex-col justify-center items-center mb-4 hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110  step-7">
          <button
            className={`${button2Class}`}
            onClick={() => {
              createChat();
            }}
          >
            <div className="w-10 h-10">
              <Icon name="newChat1" className=" text-brand-green w-10" />
            </div>
          </button>
          <p className="text-xs">New Chat</p>
        </div>

        {/* Link 3 */}

        <div className="flex flex-col justify-center items-center mb-4 hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 step-8">
          {/* Conditionally render 'New Folder' button only if there's an assistantId */}
          {assistantId && (
            <button
              className={`${button2Class} `}
              onClick={() => {
                createFolder();
              }}
            >
              <div className="w-10 h-10">
                <Icon name="newFolder" className=" text-brand-green w-10" />
              </div>
            </button>
          )}
          <p className="text-xs">New Folder</p>
        </div>
      </div>

      {/* OLD LINKS */}
      {/* <button
        className={`${button1Class} step-7`}
        onClick={() => {
          createChat();
        }}
      >
        <div className="w-6 h-4">
          <Icon name="newChat" className="mr-2" />
        </div>
        <div>New Chat</div>
      </button> */}

      {/* {assistantId && (
        <button
          className={`${button1Class} step-7`}
          onClick={() => {
            createFolder();
          }}
        >
          <div className="w-6 h-4">
            <Icon name="newChat" className="mr-2" />
          </div>
          <div>New Folder</div>
        </button>
      )} */}

      {/* Render folders conditionally if assistantId is present */}
      {assistantId &&
        allFolders.length > 0 &&
        [...allFolders]
          .reverse()
          .map((folder: any) => (
            <FolderPreview key={folder._id} folder={folder} />
          ))}

      {/* Line seperating chats and folders */}
      <div className="w-full border-t border-brand-green "></div>

      {/* Droppable area for main chat group */}
      <div ref={drop} className={dropAreaClass}>
        {allChats.length > 0 &&
          [...allChats]
            .reverse()
            .map((chat: any) => <ChatPreview key={chat._id} chat={chat} />)}
      </div>
    </div>
  );
};

export default Sidebar;
