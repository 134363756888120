import React from 'react';
import { UserButton, useUser } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import logo from '../Images/logo.png';

function MenuBar() {
  const { user } = useUser();

  // Determine the route based on whether the user is an admin
  const adminRoute = '/admin';
  const userDashboardRoute = '/dashboard'; // Adjust this to the path for the regular user dashboard
  const targetRoute = user?.publicMetadata.account === 'admin' ? adminRoute : userDashboardRoute;

  return (
    <div className="fixed top-0 left-0 w-full z-50 h-contain mx:h-[150px] flex justify-between px-2 md:px-4 py-2">
      {/* logo */}
      <div className="w-32">
        <Link to={targetRoute}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <UserButton />
    </div>
  );
}

export default MenuBar;
