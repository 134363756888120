import { useEffect, useState } from "react";
import { service } from "../../services";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const squareClass = `
        bg-brand-logo
        w-[200px]
        h-[100px]
        text-center
        rounded-2xl
        shadow-lg
        flex
        justify-center
        items-center
        hover:shadow-2xl
        hover:scale-110
        hover:transition-all
		hover:bg-brand-cream
        
        
        
        hover:cursor-pointer
        active:cursor-pointer
        p-2
		flex flex-col
		
		m-4
    `;

const AllUsers = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [users, setUsers] = useState<any>(null);

  const getUsers = async () => {
    const response = await service.adminService.getAllUsers(await getToken());
    setUsers(response);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickUser = (_id: string) => {
    navigate(`/updateUser/${_id}`);
  };

  const handleBackClick = () => {
    navigate(-1); // Takes the user back to the previous page
  };

  const renderUsers = () => {
    if (!users) return null;
    return users.map((user: any) => {
      return (
        <div
          key={user._id}
          onClick={() => handleClickUser(user._id)}
          className={squareClass}
        >
          <p className="text-brand-green text-[24px] md:text-2xl font-Black">
            {user.name}
          </p>
          <p className="text-brand-green-dark text-[12px]  font-Raleway flex-wrap max-w-[90%] leading-none">
            {user.businessProfile.businessType}
          </p>
        </div>
      );
    });
  };
  return (
    <div className="w-screen min-h-screen bg-brand-green flex flex-col items-center">
      <h1 className="text-brand-cream text-3xl md:text-7xl lg:text-[112px] xl:text-[132px] pt-16 lg:pt-2  uppercase text-brand-blue">
        All Users
      </h1>
      <p
        className="text-brand-green-dark font-Black hover:text-brand-cream hover:underline hover:cursor-pointer"
        onClick={handleBackClick}
      >
        Back
      </p>
      <div className="flex flex-wrap justify-center items-center w-full h-full p-8 bg-brand">
        {renderUsers()}
      </div>
    </div>
  );
};

export default AllUsers;
