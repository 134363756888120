import LoginForm from "./LoginForm";
import BlueGradient from "../../Images/Gradient2.svg";
import MenuBar from "../../Menu/MenuBar";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const signupFormStyle = {
  backgroundImage: `url(${BlueGradient})`,
  backgroundSize: "cover", // or any other size
};

function Login() {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  if (isSignedIn) {
    navigate("/dashboard");
  }

  return (
    <div className="h-screen" style={signupFormStyle}>
      <div className="">
        <MenuBar />
      </div>
      <div className="max-w-[1440px] mx-auto">
        <div className="w-full h-screen flex flex-col lg:flex-row justify-center items-center">
          {/* Left Col */}
          <div className="w-full lg:w-1/2 h-1/5 lg:h-full flex flex-col justify-center items-center">
            {/* mobile view */}
            <div className="w-full lg:hidden flex justify-center h-full mt-16">
              <h1 className="leading-none text-center text-7xl text-brand-logo">
                <span className="leading-none text-center text-7xl">
                  sloane<span className="font-Raleway -ml-2">.</span>
                </span>
              </h1>
            </div>

            {/* desktop view */}
            <div className="w-1/2 hidden lg:block">
              <h1 className="leading-none text-left ml-2 text-brand-logo">
                <span className="leading-none text-left ml-2 text-9xl">
                  sloane<span className="font-Raleway -ml-2">.</span>
                </span>
              </h1>
            </div>
          </div>

          {/* Left Col login*/}
          <div className="w-full lg:w-1/2 h-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-col justify-center items-center mb-20">
              <h3 className="text-brand-cream">
                <span className="text-4xl">Login</span>
              </h3>
              <div className="w-3/5 mr-12 md:mr-0 md:ml-8">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
