import { SignIn } from "@clerk/clerk-react";
import React from "react";

const LoginComponent: React.FC = () => {
	return (
		<SignIn
			redirectUrl="/dashboard"
			appearance={{
				elements: {
					footerAction: { display: "none" },
				},
			}}
		/>
	);
};

export default LoginComponent;
