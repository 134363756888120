import React from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Icon from "../../Icons/Icon";
import { useChat } from "../../../context/ChatContext";

interface RelatedAssistantsPopupProps {
	relatedAssistants: { _id: string; name: string }[];
	onClose: () => void;
	isOpen: boolean;
}

const buttonVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0 },
};

const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1,
		},
	},
};

const RelatedAssistantsPopup: React.FC<RelatedAssistantsPopupProps> = ({
	relatedAssistants = [],
	onClose,
	isOpen, // Add isOpen prop
}) => {
	const navigate = useNavigate();
	const { setSelectedChat } = useChat();

	const handleNavigate = (assistantId: string) => {
		setSelectedChat(undefined);
		navigate(`/assistant/${assistantId}`);
		onClose();
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className="absolute left-[20.5%] bottom-24 inset-0 flex items-end justify-start"
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={containerVariants}
				>
					<div>
						<ul>
							{relatedAssistants.map((assistant) => (
								<motion.li
									key={assistant._id}
									className="mb-2"
									variants={buttonVariants}
								>
									<button
										className="text-brand-green-dark text-sm border-2 border-brand-green-light hover:bg-brand-green-light  px-4 py-2 rounded-[50px] font-bold "
										onClick={() => handleNavigate(assistant._id)}
									>
										{assistant.name}
									</button>
								</motion.li>
							))}
						</ul>
						<button>
							<motion.div
								className="flex items-center text-brand-green-dark text-sm border-2 border-brand-green-light hover:bg-brand-green-light px-4 py-[6px] rounded-[50px]"
								variants={buttonVariants}
							>
								<div className="w-6 h-6 flex justify-start items-center ">
									<Icon name="canva" className="w-1/2 h-1/2 " />
								</div>
								<a
									href="https://www.canva.com/"
									target="_blank"
									rel="noreferrer"
								>
									<p className="text-sm ml-2 font-bold ">Canva</p>
								</a>
							</motion.div>
						</button>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default RelatedAssistantsPopup;
