// In ThemeSwitch.tsx
import { FormControlLabel, Switch } from "@mui/material";
import { useTheme } from "./ThemeContext"; // Adjust the import path as necessary

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme(); // Directly use the theme context
  const isDarkMode = theme === "dark";

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isDarkMode}
          onChange={toggleTheme}
          sx={{
            transform: 'scale(0.75)', // Adjust the scale to make the switch smaller
            '& .MuiSwitch-switchBase': {
              paddingY: 1.5, // Adjust the padding
              '&.Mui-checked': {
                color: '#fff', // Change the thumb color when checked
                '& + .MuiSwitch-track': {
                  backgroundColor: '#fff', // Change the track color when checked
                },
              },
            },
            '& .MuiSwitch-thumb': {
              width: 16, // Adjust the thumb size
              height: 16,
              backgroundColor: '#fff', // Change the thumb color
            },
            '& .MuiSwitch-track': {
              borderRadius: 16 / 2, // Adjust the track size
              width: 32,
              height: 16,
              backgroundColor: '#000', // Change the track color
            },
          }}
        />
      }
      label={
        <span className="text-sm"> {/* Adjust the text size here */}
          {isDarkMode ? "Dark Mode" : "Light Mode"}
        </span>
      }
    />
  );
};

export default ThemeSwitch;
