import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import NotAdmin from "./NotAdmin";
import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { service } from "../../services";
import { useEffect, useState } from "react";

const ConfigPage: React.FC = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { user } = useUser();
  const [initialAiModel, setInitialAiModel] = useState<string>("");

  const inputClass =
    "my-4 block w-full py-2 px-4 border border-brand-logo rounded-full bg-transparent text-brand-green-dark placeholder-green-700 focus:border-brand-green-dark outline-none ";
  const labelClass = "block text-brand-logo font-bold mb-2 text-xl";

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const token = await getToken();
        const config = await service.adminService.getConfig(token); // Assuming you have a service to fetch config
        setInitialAiModel(config[0].aiService);
      } catch (error) {
        console.error("Failed to fetch config", error);
      }
    };

    fetchConfig();
  }, [getToken]);

  if (user?.publicMetadata.account !== "admin") {
    return <NotAdmin />;
  }
  const handleBackClick = () => {
    navigate(-1); // Takes the user back to the previous page
  };
  return (
    <div className="bg-brand-green">
      <div className="flex flex-col justify-center items-center w-screen-1 py-8 min-h-screen max-w-[1440px] mx-auto">
        <h2 className="text-xl lg:text-4xl font-bold mb-4 text-brand-cream">
          Choose Ai Model
        </h2>
        <p
          className="text-brand-green-dark font-Black hover:text-brand-cream hover:underline hover:cursor-pointer"
          onClick={handleBackClick}
        >
          Back
        </p>
        <Formik
          initialValues={{
            aiModelId: initialAiModel,
          }}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true); // Start submitting

            try {
              const token = await getToken();
              await service.adminService.setAiModel(token, values.aiModelId);
              // addPrompt(values);
              navigate("/admin"); // Navigate back on successful submit
            } catch (error) {
              console.error("Failed to submit form", error);
            }

            setSubmitting(false); // Finish submitting
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="space-y-4 w-1/2">
              <div>
                <label htmlFor="aiModelId" className={labelClass}>
                  Ai Model
                </label>
                <Field
                  as="select"
                  id="aiModelId"
                  name="aiModelId"
                  className={inputClass}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue("aiModelId", e.target.value);
                  }}
                >
                  <option value="" label="Select Ai" />
                  <option value="gemini" label="GoogleGemini" />
                  <option value="openAi" label="ChatGPT" />
                </Field>
              </div>

			  <button
                type="submit"
                disabled={isSubmitting}
                className={`mt-4 bg-brand-cream text-brand-green py-2 px-4 rounded-full hover:bg-brand-green-dark hover:text-brand-logo transition duration-300 font-Black ${
                  isSubmitting
                    ? "opacity-50"
                    : "hover:bg-brand-green-dark hover:text-brand-logo"
                } transition-colors duration-300 ease-in-out mt-8`}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ConfigPage;
