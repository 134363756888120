import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm"; // Import the remark-gfm plugin
import { useTheme } from "./ThemeContext";

interface IMarkdownProps {
  data: string; // The markdown content as a string
  markdownClassesProps?: string;
}

const Markdown: React.FC<IMarkdownProps> = ({ data, markdownClassesProps }) => {
  const { theme } = useTheme();

  // Define custom renderers for Markdown components, including table elements
  const markdownRenderers: Components = {
    h1: ({ node, ...props }) => (
      <h1 className="text-4xl font-semibold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h1>
    ),
    h2: ({ node, ...props }) => (
      <h2 className="text-2xl font-semibold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h2>
    ),
    h3: ({ node, ...props }) => (
      <h3 className="text-xl font-bold" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h3>
    ),
    h4: ({ node, ...props }) => (
      <h4 className="text-lg font-bold underline" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h4>
    ),
    h5: ({ node, ...props }) => (
      <h5 className="text-base font-bold underline" {...props}>
        {props.children || <span className="sr-only">Untitled</span>}
      </h5>
    ),
    p: ({ node, ...props }) => (
      <p className="text-base font-light mb-4" {...props} />
    ),
    ul: ({ node, ...props }) => <ul className="mb-4" {...props} />,
    li: ({ node, ...props }) => (
      <li className="list-disc ml-6 font-thin py-2" {...props} />
    ),
    hr: ({ node, ...props }) => (
      <hr className="border-t-[1px] border-black opacity-30 my-4" {...props} />
    ), // Custom divider line
    strong: ({ node, ...props }) => (
      <strong className="font-semibold" {...props} />
    ),
    em: ({ node, ...props }) => <em className="italic" {...props} />,
    del: ({ node, ...props }) => <del className="line-through" {...props} />,

    // Custom table renderer with solid lines and shaded headers
    table: ({ node, ...props }) => (
      <table className="w-full border-collapse border border-black" {...props} />
    ),
    thead: ({ node, ...props }) => (
      <thead className="bg-gray-200" {...props} />
    ),
    th: ({ node, ...props }) => (
      <th
        className="border border-black px-4 py-2 text-left font-bold bg-brand-green-light"
        {...props}
      />
    ),
    tbody: ({ node, ...props }) => (
      <tbody className="divide-y divide-black" {...props} />
    ),
    td: ({ node, ...props }) => (
      <td className="border border-black px-4 py-2" {...props} />
    ),
    pre: ({ node, inline, className, children, ...props }: any) => {
      return (
        <Markdown
          data={children?.props?.children}
          markdownClassesProps={"text-brand-green-dark hover:text-green-dark "}
        />
      );
    },
  };

  // Determine the styling class based on the current theme
  const markdownClasses =
    theme === "dark" ? "text-brand-green-dark" : "text-brand-green-dark";

  return (
    <ReactMarkdown
      className={markdownClassesProps || markdownClasses}
      components={markdownRenderers}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]} // Add remark-gfm to support tables
    >
      {data}
    </ReactMarkdown>
  );
};

export default Markdown;
