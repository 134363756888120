// PleaseSubscribe.tsx

import React from "react";

interface PleaseSubscribeProps {
  onClickSubscribe: () => void; // Assuming onClickSubscribe is a function that takes no arguments and returns void
}

const PleaseSubscribe: React.FC<PleaseSubscribeProps> = ({
  onClickSubscribe,
}) => {
  return (
    <div className="text-center bg-brand-green h-screen  flex flex-col justify-center items-center text-brand-orange ">
      <h2 className="text-brand-cream text-3xl md:text-7xl">
        Subscription Needed
      </h2>
      <p className="text-brand-cream mt-4 text-2xl">
        Access to sloane. requires an active subscription.
      </p>
      {/* Include a link or button directing to the subscription page */}
      <a
        href="https://app.sloane.biz/userform"
        className="text-brand-green font-black mt-8 inline-block uppercase text-lg bg-brand-logo px-8 py-2 rounded-full hover:text-brand-green-dark transition-all duration-300 ease-in-out"
      >
        Subscribe Now
      </a>
      <p className="text-brand-cream mt-8 text-[21px]">
        Member{" "}
        <a
          href="https://app.sloane.biz/"
          className="text-brand-cream underline mt-4"
        >
          login here{" "}
        </a>{" "}
      </p>
      {/* external link */}
    </div>
  );
};

export default PleaseSubscribe;
